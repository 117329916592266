<template>
  <div class="van-hairline--top px-6 pb-6">
    <van-collapse v-model="activeNames" class="az-collapse">
      <!-- About Section -->
      <van-collapse-item name="1">
        <template #title>
          <span class="text-sm text-#121212">{{ t('key.footer.company') }}</span>
        </template>
        <ul class="space-y-1">
          <li v-for="link in aboutLinks" :key="link.text">
            <NuxtLink :to="link.url" class="text-gray-700 hover:text-gray-900">
              {{ link.text }}
            </NuxtLink>
          </li>
        </ul>
      </van-collapse-item>
      <van-collapse-item name="2">
        <!-- Contact Section -->
        <template #title>
          <span class="text-sm text-#121212">{{ t('key.footer.help') }}</span>
        </template>
        <ul class="mb-5 space-y-1">
          <li>
            <NuxtLink to="https://corporate.azgotrip.com/contact-us" class="text-gray-700 hover:text-gray-900">
              {{ t('key.footer.contact.us') }}
            </NuxtLink>
          </li>
          <li v-if="!isBurnCoinsScene">
            <NuxtLink to="https://corporate.azgotrip.com/faq" class="text-gray-700 hover:text-gray-900">
              {{ t('key.footer.faqs') }}
            </NuxtLink>
          </li>
        </ul>
      </van-collapse-item>

      <van-collapse-item name="3">
        <template #title>
          <span class="text-sm text-#121212">{{ t('key.footer.partnerships') }}</span>
        </template>
        <ul class="mb-5 space-y-1" font="400">
          <li v-for="link in partnershipsLinks" :key="link.text">
            <NuxtLink :to="link.url" :external="true" class="text-gray-700 hover:text-gray-900">
              <span>{{ link.text }}</span>
            </NuxtLink>
          </li>
        </ul>
      </van-collapse-item>
    </van-collapse>

    <!-- Footer Section -->
    <footer class="mb-4 mt-8 text-center text-xs text-#808080 font-300">
      <div class="mb-2">
        <NuxtLink to="https://corporate.azgotrip.com/terms-of-use" class="text-#808080" :external="true">
          {{ t('key.footer.terms') }}
        </NuxtLink>
        <van-divider vertical />
        <NuxtLink to="https://corporate.azgotrip.com/privacy-policy" class="text-#808080" :external="true">
          {{ t('key.footer.privacy.policy') }}
        </NuxtLink>
      </div>
      <div>
        <p>{{ t('key.footer.copyright') }}</p>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const { t } = useI18n()
const { isBurnCoinsScene } = useGlobalStore()

const activeNames = ref([])

const aboutLinks = computed(() => [
  { text: t('key.footer.about.us'), url: 'https://corporate.azgotrip.com/about-us' },
  { text: t('key.footer.products'), url: 'https://corporate.azgotrip.com/products' },
  { text: t('key.footer.press'), url: 'https://corporate.azgotrip.com/press-media-coverage' },
])

const partnershipsLinks = computed(() => [
  { text: t('key.footer.solutions'), url: 'https://corporate.azgotrip.com/solutions' },
  { text: t('key.footer.become.partner'), url: 'https://corporate.azgotrip.com/partnership-enquiry' },
  { text: t('key.footer.become.distributor'), url: 'https://corporate.azgotrip.com/affiliate-program' },
])
</script>

<style lang="scss">
.az-collapse {
  .van-cell {
    padding: 12px 0;
    background-color: transparent;
  }

  .van-collapse-item {
    &__content {
      padding: 0 0 12px 0;
    }

    &__title--expanded:after {
      display: none;
    }

    &--border:after {
      left: 0;
      right: 0;
    }

    &__content {
      background-color: transparent;
    }
  }
}
</style>
